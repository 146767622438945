import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useFeatureMessageQuery = () => {
  const { allSanityFeatureMessage } = useStaticQuery(graphql`
    query {
      allSanityFeatureMessage(sort: { fields: _createdAt, order: ASC }) {
        nodes {
          title
          featureText
          bgImage {
            ...ImageWithPreview
          }
          featureLink {
            ...PageLink
          }
        }
      }
    }
  `);
  return allSanityFeatureMessage || {};
};
