import React, { useState } from "react";
import { graphql } from "gatsby";
import { useFeatureMessageQuery } from "@querys/useFeatureMessageQuery";
import { useJobQuery } from "@querys/useJobQuery";
import { SanityImage } from "@components/sanity/sanityImage";
import cx from "classnames";
import { Link } from "gatsby";
import Plus from "@svg/plus.svg";
import Next from "@svg/next.svg";
import { motion } from "framer-motion";
import { PageMeta } from "@components/pageMeta";
import Layout from "@components/layout";
import FeatureMessage from "@components/nav/featureMessage";
import TitleBlock from "@components/pageModules/modules/titleBlock";

const Post = ({ slug, title, excerpt, featuredImage, index }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  return (
    <motion.div
      className={cx(
        "px-gutter sm:px-0 flex flex-col sm:grid sm:grid-cols-14 text-dark-grey",
        {
          "mt-0": index === 0,
          "mt-20": index !== 0,
        }
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="col-start-2 col-end-8">
        <div className="text-md ">{title}</div>
        <div className="mt-4 mb-8 text-xs">{excerpt}</div>
        <div
          className="flex"
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
          role="button"
        >
          <motion.div
            animate={{ x: hoveredIndex !== index ? 0 : [0, -8, 0] }}
            transition={{
              duration: 1,
              repeat: hoveredIndex !== index ? null : Infinity,
            }}
            className="cursor-pointer "
          >
            <Next className="w-10 mr-4 pt-[2px]" />
          </motion.div>

          <Link
            to={`/careers/${slug?.current}`}
            className="text-base text-dark-green link-underline link-underline-dark"
          >
            Read more
          </Link>
        </div>
      </div>
      <SanityImage
        className="mt-10 sm:mt-0 col-start-9 col-end-14 w-full h-full"
        image={featuredImage}
      />
    </motion.div>
  );
};

const CareersTemplate = ({ data: { page } }) => {
  const { seo, title, excerpt, pageColour } = page || {};
  const { nodes } = useFeatureMessageQuery();
  const { vacancies } = useJobQuery();

  const [vacanciesAmount, setVacanciesAmount] = useState(3);
  const filteredVacancies = vacancies.slice(0, vacanciesAmount);

  const handleMore = () => {
    setVacanciesAmount((vacanciesAmount) => vacanciesAmount + 3);
  };

  const bottomFeaturedMessage = nodes[1];

  return (
    <Layout reverseHeader={true} pageColour={pageColour}>
      <PageMeta {...seo} />
      <div className="pt-60 ">
        <TitleBlock header={title} firstText={excerpt} />
      </div>
      <div className="flex flex-col pt-40 pb-36">
        {vacancies.length === 0 && (
          <h1 className="px-gutter">No current vacancies!</h1>
        )}
        {filteredVacancies?.map((post, i) => {
          return <Post key={i} index={i} {...post} />;
        })}
        {vacanciesAmount <= vacancies.length && (
          <motion.div
            className="mt-32 mx-auto flex cursor-pointer "
            onClick={handleMore}
            role="button"
            whileHover={{
              scale: 1.1,
            }}
          >
            <Plus className="w-[24px] mr-4 pt-1 " />
            <p className="blockH6 text-dark-green">See More</p>
          </motion.div>
        )}
      </div>
      {bottomFeaturedMessage && <FeatureMessage {...bottomFeaturedMessage} />}
    </Layout>
  );
};

export default CareersTemplate;

export const careersPageQuery = graphql`
  query standardCareersPageQuery {
    page: sanityCareersPage {
      seo {
        ...SEO
      }
      title
      pageColour {
        title
        value
      }
      excerpt
    }
  }
`;
