import React from "react";
import ModuleWrapper from "../moduleWrapper";

function TitleBlock({ header, firstText, secondaryText, config }) {
  return (
    <ModuleWrapper {...config}>
      <div className="md:grid grid-cols-14 px-gutter md:px-0">
        <h1 className="blockH7 tracking-[.1em] text-vibrant-green mb-9 col-start-2 col-end-14 lg:col-end-5">
          {header?.toUpperCase()}
        </h1>
        <p className="text-lg md:text-2xl text-dark-grey mb-9 col-start-2 col-end-12">
          {firstText}
        </p>
        <p className="blockH7 text-dark-grey col-start-2 col-end-12">
          {secondaryText}
        </p>
      </div>
    </ModuleWrapper>
  );
}

export default TitleBlock;
