import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useJobQuery = () => {
  const { allSanityJob } = useStaticQuery(graphql`
    query {
      allSanityJob {
        vacancies: nodes {
          title
          excerpt
          slug {
            current
          }
          featuredImage {
            ...ImageWithPreview
          }
          content {
            ...PageModules
          }
        }
      }
    }
  `);
  return allSanityJob || {};
};
