import React from "react";
import { SanityImage } from "@components/sanity/sanityImage";
import SwitchLink from "../links/switchLink";

const FeatureMessage = ({ title, featureText, bgImage, featureLink }) => {
  return (
    <div className="h-[600px] w-full relative flex items-center justify-start">
      <div className="relative z-10 grid grid-cols-14">
        <div className="col-start-1 col-end-2 lg:col-end-3 bg-white" />
        <div className="col-start-2 lg:col-start-3 col-end-14 lg:col-end-10 border-8 border-white px-10 lg:px-20 pt-16 pb-10">
          <h1 className="text-white text-lg lg:text-xl leading-tight ">
            {title}
          </h1>
          <h2 className="text-white text-sm md:text-md  pt-4 pb-6 ">
            {featureText}
          </h2>
          <SwitchLink
            className="text-white text-sm tracking-[.1em] link-underline link-underline-white pb-2 cursor-pointer"
            {...featureLink}
          />
        </div>
      </div>

      <SanityImage
        image={bgImage}
        className="absolute z-0 w-full h-full top-0 left-0 object-cover"
      />
    </div>
  );
};

export default FeatureMessage;
